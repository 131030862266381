import React from "react";
import KeyActivities from "../../Components/About/KeyActivities";
import TopSection from "../../Components/About/TopSection";
import SimpleBanner from "../Common/SimpleBanner";
import AboutUs_banner from '../../Assets/Home/AboutUs1.png'

const CouncilProfile = () => {
  return (
    <div>
      <SimpleBanner
        image={AboutUs_banner}
        name="Council Profile"
        path="Home >> About >> Council Profile"
      />
      <TopSection />
      <KeyActivities />
    </div>
  );
};

export default CouncilProfile;
