import React from "react";
import image from "../../Assets/About/Council Profile.png";
import logo from "../../Assets/logo/Logo.jpg";

function TopSection() {
  return (
    <div className="p-4 lg:p-24 pb-4 lg:pb-12 md:px-12 lg:px-16 xl:px-44 flex flex-col lg:flex-row gap-16">
      <img
        src={image}
        alt="MSME Image"
        className="w-full lg:w-[40%] h-full"
        data-aos="flip-up"
      />
      <div className="flex flex-col gap-8">
        <span className="bg-orange-100 text-[#eab308] border-r-4 border-[#eab308] px-4 py-2 rounded-xl text-sm font-medium w-full md:w-1/3">
          Council Profile
        </span>
        <p className="text-md md:text-lg text-[#4f4f4f] " data-aos="fade-left">
          MSME Promotion Council Board India is established to promote the
          development and growth of micro, small, and medium enterprises (MSMEs)
          in India. The Council was established in 2008 with the objective of
          providing a platform for MSMEs to showcase their products and
          services, access finance, and connect with potential customers and
          partners. <br />
          <br />
          The Council works closely with the government, industry associations,
          and other stakeholders to create an enabling environment for MSMEs to
          thrive. It provides a range of services to MSMEs, including capacity
          building, market development, technology upgradation, and policy
          advocacy. <br />
          <br />
          The MSME Promotion Council Board India is a non-profit organization
          has a nationwide network of offices and partners, and it works with a
          wide range of stakeholders, including MSMEs, industry associations,
          financial institutions, and government agencies. The Council’s
          activities are focused on promoting inclusive and sustainable economic
          growth by empowering MSMEs to become more competitive and innovative.
        </p>
        
      </div>
    </div>
  );
}

export default TopSection;
