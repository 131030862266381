import React from "react";
import director from "../../Assets/Home/Chairman.jpg";
function ChairmanMessage() {
  return (
    <div className="p-4 lg:p-24 pb-4 lg:pb-12 md:px-12 lg:px-16 xl:px-44 flex flex-col gap-4 lg:gap-16">
      <div className="bg-white flex flex-col lg:flex-row gap-8">
        <img src={director} alt="" className="w-full h-full lg:h-full xl:h-[500px]" />

        <div className="flex flex-col p-4 md:p-8 xl:p-16 gap-6">
          <h1 className="text-2xl md:text-4xl lg:text-5xl text-[#141f39] font-extrabold">
            BIBHUTI BHUSAN LENKA
          </h1>
          <p className="text-md md:text-lg text-[#ff965a] font-bold">
            National Chairman MSME PCBI (India)
          </p>
          <ul className="list-disc list-inside text-[#4f4f4f] text-md md:text-lg">
            <li className="mb-2">Management & Corporate Structure Trainer</li>
            <li className="mb-2">Agricultural Advisor & Social Expert</li>
          </ul>
          <p className="text-md md:text-lg text-[#4f4f4f] xl:mt-8">Micro enterprises are the backbone of the Indian economy. The country's high poverty rate and low per capita income compared to developed nations highlight the critical role these businesses play in driving local economies and enhancing community resilience.</p>
        </div>
      </div>

      <p className="leading-relaxed text-md md:text-lg text-[#4f4f4f]">As a developing country, India must prioritize the growth and sustainability of micro, small, and medium enterprises (MSMEs). These businesses often face significant challenges that hinder their development. Many corporations and agencies share a similar intent to support the micro entrepreneurship community, but few have the necessary ecosystem to effectively do so. <br/> <br />

      I believe that by empowering micro entrepreneurs, particularly those below the poverty line, we can significantly contribute to India's economic and social growth. The MSME Promotion Council Board, India (MSME PCBI) has inspired me to dedicate my efforts to this cause. <br /> <br />

      Our organization is working nationwide to empower women and support the growth of micro, small, and medium enterprises. We are committed to providing training, assistance, and resources to help these businesses thrive. <br /><br />

      I am grateful for the dedication and commitment of our team, who work tirelessly without seeking personal profit. With their strategic approach and hard work, I believe we can elevate our organization to new heights and make a significant impact on the Indian economy.</p>
    </div>
  );
}

export default ChairmanMessage;
