import React, { useState } from "react";
import logo from "../../Assets/logo/logo.png";
import img1 from "../../Assets/logo/4.png";
import img2 from "../../Assets/logo/5.png";
import img3 from "../../Assets/logo/digital-india.png";
import img4 from "../../Assets/logo/make_in_india.png";
import img5 from "../../Assets/logo/msme-tag.png";
import { FaBars, FaTimes } from "react-icons/fa";
import "./styles.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAboutDropdownOpen, setIsAboutDropdownOpen] = useState(false);
  const [isCommitteesDropdownOpen, setIsCommitteesDropdownOpen] =
    useState(false);

  const toggleSidebar = () => setIsOpen(!isOpen);

  const handleAboutClick = () => {
    setIsAboutDropdownOpen((prev) => !prev);
  };

  const handleOptionClick = () => {
    setIsAboutDropdownOpen(false);
    setIsCommitteesDropdownOpen(false);
    setIsOpen(false);
  };

  const handleCommitteesClick = () => {
    setIsCommitteesDropdownOpen((prev) => !prev);
  };

  return (
    <nav className="bg-gray-800 text-white">
      {/* Top Bar with Logos */}
      <div className="bg-[#534e0d]">
        <div className="flex justify-between md:justify-center items-center py-4 px-6">
          <img src={logo} alt="logo" className="h-16 md:hidden" />

          <div className="items-center space-x-4 hidden md:flex">
            <img
              src={img1}
              alt="Azadi ka Amrit Mahotsav logo"
              className="h-16"
            />

            <img src={img2} alt="G20 logo" className="h-16" />
            <img src={img3} alt="Digital India logo" className="h-16" />
            <img src={img4} alt="Make in India logo" className="h-16" />
          </div>

          {/* Menu Icon for Mobile */}
          <div className="md:hidden">
            <FaBars
              onClick={toggleSidebar}
              size={24}
              className="cursor-pointer bg-black"
            />
          </div>
        </div>
      </div>
      <div className="overflow-hidden whitespace-nowrap bg-black">
        <div className="inline-block animate-marquee text-base md:text-lg font-bold text-white">
        Address: C 98, Hanuman Mandir Rd, Kucha Pati Ram, Connaught Place, New Delhi, Delhi, 110001
        </div>
      </div>
      {/* Sidebar for Mobile */}
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-50 z-50 transform ${isOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 md:hidden`}
      >
        <div className="absolute top-0 left-0 w-3/4 bg-white h-full shadow-lg flex flex-col p-6">
          <div className="flex justify-between items-center">
            <img src={logo} alt="logo" className="h-12" />
            <FaTimes
              onClick={toggleSidebar}
              size={24}
              className="cursor-pointer text-gray-800"
            />
          </div>

          <nav className="mt-6 space-y-4">

            <a
              href="/"
              onClick={handleOptionClick}
              className="text-gray-700 hover:text-gray-900"
            >
              Home
            </a>

            {/* About Dropdown */}
            <div>
              <button
                onClick={handleAboutClick}
                className="flex justify-between items-center w-full text-gray-700 hover:text-gray-900"
              >
                About
                <span>{isAboutDropdownOpen ? "-" : "+"}</span>
              </button>
              {isAboutDropdownOpen && (
                <div className="pl-4 mt-2 flex flex-col space-y-2">
                  <a
                    href="/ourMainObjectives"
                    onClick={handleOptionClick}
                    className="text-gray-600 hover:text-gray-800"
                  >
                    Our Main Objective
                  </a>
                  <a
                    href="/councilProfile"
                    onClick={handleOptionClick}
                    className="text-gray-600 hover:text-gray-800"
                  >
                    Council Profile
                  </a>
                  <a
                    href="/chairmanMessage"
                    onClick={handleOptionClick}
                    className="text-gray-600 hover:text-gray-800"
                  >
                    Chairman Message
                  </a>
                </div>
              )}
            </div>

            {/* Committees Dropdown */}
            <div>
              <button
                onClick={handleCommitteesClick}
                className="flex justify-between items-center w-full text-gray-700 hover:text-gray-900"
              >
                Committees
                <span>{isCommitteesDropdownOpen ? "-" : "+"}</span>
              </button>
              {isCommitteesDropdownOpen && (
                <div className="pl-4 mt-2 flex flex-col space-y-2">
                  <a
                    href="/centralCommittees"
                    onClick={handleOptionClick}
                    className="text-gray-600 hover:text-gray-800"
                  >
                    Central Committees
                  </a>
                  <a
                    href="/stateWiseCommittee"
                    onClick={handleOptionClick}
                    className="text-gray-600 hover:text-gray-800"
                  >
                    State-Wise Committee
                  </a>
                  <a
                    href="/districtWiseCommittee"
                    onClick={handleOptionClick}
                    className="text-gray-600 hover:text-gray-800"
                  >
                    District-Wise Committee
                  </a>
                </div>
              )}
            </div>
            <a href="/contact" >
              <button
                className="bg-orange-500 mt-6 text-white px-4 py-2 rounded w-full text-center"
              >
                Contact Us
              </button>
            </a>

          </nav>
        </div>
      </div>

      {/* Desktop Navbar */}
      <div className="bg-[#534e0d]  hidden py-2 md:px-12 lg:px-8 xl:px-16 md:block">
        <div className="flex justify-between items-center">
          <img src={logo} alt="logo" className="h-16" />
          <div className="flex space-x-6 text-lg">
            <a href="/" className="text-gray-900">
              Home
            </a>
            <div className="relative">
              <button
                onClick={handleAboutClick}
                className="text-gray-900"
              >
                About
              </button>
              {isAboutDropdownOpen && (
                <div className="absolute z-50 left-0 top-full mt-2 w-48 bg-white shadow-lg rounded-lg">
                  <ul className="text-gray-700 text-left p-2">
                    <li className="py-1 hover:bg-gray-200">
                      <a href="/ourMainObjectives" onClick={handleOptionClick}>
                        Our Main Objective
                      </a>
                    </li>
                    <li className="py-1 hover:bg-gray-200">
                      <a href="/councilProfile" onClick={handleOptionClick}>
                        Council Profile
                      </a>
                    </li>
                    <li className="py-1 hover:bg-gray-200">
                      <a href="/chairmanMessage" onClick={handleOptionClick}>
                        Chairman Message
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="relative">
              <button
                onClick={handleCommitteesClick}
                className="text-gray-900"
              >
                Committees
              </button>
              {isCommitteesDropdownOpen && (
                <div className="absolute z-50 left-0 top-full mt-2 w-48 bg-white shadow-lg rounded-lg">
                  <ul className="text-gray-700 text-left p-2">
                    <li className="py-1 hover:bg-gray-200">
                      <a href="/centralCommittees" onClick={handleOptionClick}>
                        Central Committees
                      </a>
                    </li>
                    <li className="py-1 hover:bg-gray-200">
                      <a href="/stateWiseCommittee" onClick={handleOptionClick}>
                        State-Wise Committee
                      </a>
                    </li>
                    <li className="py-1 hover:bg-gray-200">
                      <a
                        href="/districtWiseCommittee"
                        onClick={handleOptionClick}
                      >
                        District-Wise Committee
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="flex space-x-4">
            <a href="/contact">
              <button className="py-2 px-4 bg-[#eab308] text-white font-bold hover:bg-[#ca9a04] transition-all ease-in-out rounded-md">
                Contact Us
              </button>
            </a>
            {/* <button className="py-2 px-4 bg-[#eab308] text-white font-bold hover:bg-[#ca9a04] transition-all ease-in-out">
              Contact Us
            </button> */}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
