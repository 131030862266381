import React from "react";

import img1 from "../../Assets/Home/Chairman.jpg";
import img2 from "../../Assets/Home/Bibhuti.jpg";
import img3 from "../../Assets/Home/Kulwinder.jpg";
import img4 from "../../Assets/Home/Gopal.jpg";
import img5 from "../../Assets/Home/Rajkumar.jpg";
import img6 from "../../Assets/Home/Vice Chairman(India).jpg";
import ActivitiesSwiper from "./ActivitiesSwiper";

const Activities = () => {
  return (
    <div className=" p-4 md:p-8 lg:p-24 pb-4 md:px-12 lg:px-16 xl:px-44 gap-8"data-aos="flip-up">
      <h1 className="text-3xl md:text-5xl mb-6 text-[#141f39] font-extrabold text-center font-serif">
        Our <span className="text-[#eab308]">Prominent</span> Personalities
      </h1>
      <div>
        <ActivitiesSwiper data-aos="flip-down"/>
      </div>
    </div>
  );
};

export default Activities;
