import { useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";

// import LASTIMG from "../../Assets/Home/f";
import LASTIMG from "../../Assets/Home/FAQs.png";
const FaqSection = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleDropdown = (id) => {
    setOpenFAQ(openFAQ === id ? null : id);
  };
  return (
    <div className="p-4 lg:p-24 pb-4 lg:pb-12 md:px-12 lg:px-16 xl:px-44 " data-aos="zoom-in">
      <h1 className="text-3xl md:text-5xl mb-6 text-[#141f39] font-extrabold text-center">
        People Frequently Asked Questions
      </h1>
      <div className="flex flex-col lg:flex-row justify-center items-center gap-6">
        {/* Images Section */}
        <div className="w-full lg:w-[50%]">
          
            <img src={LASTIMG} alt="No Image" className="w-full h-[30rem] object-cover" />
        
        </div>
        <div className="space-y-4 text-left w-full lg:w-[50%]">
          {/* ---------------------------------------------- */}
          <div className="bg-white rounded-md p-3">
            <div
              className={` p-4 rounded-lg flex justify-between items-center cursor-pointer `}
              onClick={() => toggleDropdown("faqA")}
            >
              <h2
                className={`text-[17.14px] font-medium ${
                  openFAQ === "faqA" ? "text-yellow-500 " : "text-black"
                }`}
              >
                Who is eligible to participate in the program ?
              </h2>
              <MdArrowForwardIos
                className={`fas ${
                  openFAQ === "faqA" ? "rotate-90" : "rotate-0"
                } transition-transform duration-300`}
              />
            </div>
            {openFAQ === "faqA" && (
              <div className="p-4 rounded-lg text-[15.43px] font-normal">
                <p>
                  The MSME Promotion Council Board INDIA program is a organization
                  initiative aimed at supporting and promoting Micro, Small, and
                  Medium Enterprises (MSME) across various sectors by providing
                  them with essential resources, guidance, and opportunities to
                  thrive in the competitive business landscape.
                </p>
              </div>
            )}
          </div>

          {/* -------------------------------------------------- */}
          <div className="bg-white rounded-md p-3">
            <div
              className={`  p-4 rounded-lg flex justify-between items-center cursor-pointer `}
              onClick={() => toggleDropdown("faq1")}
            >
              <h2
                className={`text-[17.14px] font-medium ${
                  openFAQ === "faq1" ? "text-yellow-500" : "text-black"
                }`}
              >
                What benefits does the program offer for the business ?
              </h2>
              <MdArrowForwardIos
                className={`fas ${
                  openFAQ === "faq1" ? "rotate-90" : "rotate-0"
                } transition-transform duration-300`}
              />
            </div>
            {openFAQ === "faq1" && (
              <div className="p-4 rounded-lg text-[15.43px] font-normal">
                <p>
                  Any registered Micro, Small, or Medium Enterprise operating in
                  INDIA can participate in the MSME Council Board INDIA program. The
                  eligibility criteria are defined based on the enterprise’s
                  size, investment in plant and machinery, and annual turnover.
                </p>
              </div>
            )}
          </div>

          <div className="bg-white rounded-md p-3">
            <div
              className={` p-4 rounded-lg flex justify-between items-center cursor-pointer `}
              onClick={() => toggleDropdown("faq2")}
            >
              <h2
                className={` text-[17.14px] font-medium ${
                  openFAQ === "faq2" ? "text-yellow-500" : "text-black"
                }`}
              >
                How can Business connect with MSME PCBI ?
              </h2>
              <MdArrowForwardIos
                className={`fas ${
                  openFAQ === "faq2" ? "rotate-90" : "rotate-0"
                } transition-transform duration-300`}
              />
            </div>
            {openFAQ === "faq2" && (
              <div className=" text-[15.43px] font-normal p-4 rounded-lg">
                <p>
                  Businesses can connect with MSME Promotion Council Board INDIA (MSME
                  PCBI) through its ficial website or designated contact points.
                  Explore collaboration, support, and growth opportunities for
                  mutual success..
                </p>
              </div>
            )}
          </div>

          

          {/* ----- */}
          <div className="bg-white rounded-md p-3">
            <div
              className={` p-4 rounded-lg flex justify-between items-center cursor-pointer `}
              onClick={() => toggleDropdown("faq4")}
            >
              <h2
                className={`text-[17.14px] font-medium ${
                  openFAQ === "faq4" ? "text-yellow-500" : "text-black"
                }`}
              >
                What kind support MSME PCBI program provide for growth ?
              </h2>
              <MdArrowForwardIos
                className={`fas ${
                  openFAQ === "faq4" ? "rotate-90" : "rotate-0"
                } transition-transform duration-300`}
              />
            </div>
            {openFAQ === "faq4" && (
              <div className="p-4 rounded-lg text-[15.43px] font-normal">
                <p>
                  The MSME PCBI program fers mentorship, skill development,
                  financing access, certification guidance, and collaboration
                  opportunities to foster Micro, Small, and Medium Enterprise
                  growth in INDIA’s competitive landscape.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
