import React from 'react'
import business1 from '../../Assets/Home/business1.png'
import business2 from '../../Assets/Home/business2.png'
import business3 from '../../Assets/Home/business3.png'

function AboutUs() {
  return (
    <div>
      <div className="p-4 lg:p-24 pb-4 lg:pb-12 md:px-12 lg:px-16 xl:px-44">
          <div className="flex flex-wrap space-y-4"data-aos="fade-up">
            <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-start">
              <div className="relative mb-6">
                <img
                  src="https://storage.googleapis.com/a1aa/image/e8od31VfkKo4AU1pbysfgpABpC9lRKAbvqaNnE4R7eHrbr7OB.jpg"
                  alt="People working in a manufacturing unit"
                  className="w-full h-auto rounded-lg shadow-lg"
                />
                <div className="absolute bottom-0 left-0 transform translate-x-1/4 translate-y-1/4">
                  <img
                    src="https://storage.googleapis.com/a1aa/image/RneTJdEq8YWftUj83kYzH4rqjgYmeTRDwmSeC04fLWOm3W3dC.jpg"
                    alt="Woman talking on the phone while working on a laptop"
                    className="w-24 h-24 rounded-full border-4 border-white shadow-lg"
                  />
                </div>
              </div>
              
            </div>
            <div className="w-full lg:w-1/2 lg:pl-12" data-aos="fade-down">
              <div className="mb-2">
                <span className="bg-orange-100 text-[#eab308] border-r-4 border-[#eab308] px-4 py-2 rounded-xl text-sm font-medium">
                  ABOUT MSME PCBI
                </span>
              </div>
              <h1 className="text-4xl font-bold text-gray-900 mb-2  tracking-wide leading-normal"
              style={{ fontFamily: "Cinzel, serif" }}>
                Promotion Council <span className='text-[#eab308]'>Board</span> India
              </h1>
              <p className="text-gray-700 mb-6 tracking-wide leading-relaxed">
                MSME PCBI works for the development MSME from manufacturing, service sectors, Trading and allied industrial / business sectors and shall be integrating Micro, Small and Medium Enterprises, Banks, Financial Institutions, Policy makers, Young & Women entrepreneurs and Start–Ups to establish and enhance contacts for better business growth and expansion.
              </p>
              <div className="  rounded-lg  mb-8">
                <div className="flex items-start mb-4">
                  <div className="bg-orange-100 p-3 rounded-full">
                    <img src={business1} alt="" />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg font-bold ">
                      Small & Medium Enterprises
                    </h3>
                    <p className="text-gray-700 tracking-wide leading-relaxed">
                      Provide Handholding to the small and medium scale Industries such as Microloans and Educate about govt schemes
                    </p>
                  </div>
                </div>
                <div className="flex items-start mb-4">
                  <div className="bg-orange-100  p-3 rounded-full">
                  <img src={business2} alt="" />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg font-bold">
                      Service Sector Companies
                    </h3>
                    <p className="text-gray-700 tracking-wide leading-relaxed">
                      MSME promotion boosts service sector growth through financial support, tax benefits, and enhanced efficiency.
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="bg-orange-100 p-3 rounded-full">
                  <img src={business3} alt="" />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg font-bold">
                      State Level MSME Corporation
                    </h3>
                    <p className="text-gray-700 tracking-wide leading-relaxed">
                      MSME promotion supports state-level MSME corporations by providing financial assistance, facilitating infrastructure development
                    </p>
                  </div>
                </div>
              </div>
              <button className="bg-[#eab308] hover:bg-[#ca9a04] text-white px-6 py-3 font-medium rounded-lg ">
                Read More
              </button>
            </div>
          </div>
        </div>
    </div>
  )
}

export default AboutUs
