
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import img3 from '../../Assets/Home/telephone.png';
import img2 from '../../Assets/Home/email.png';
import img1 from '../../Assets/Home/address.png';

const GetInTouch = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormValues({ ...formValues, [id]: value });
  };

  const validate = () => {
    const newErrors = {};
    if (!formValues.name) newErrors.name = 'Name is required';
    if (!formValues.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = 'Email address is invalid';
    }
    if (!formValues.phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formValues.phone)) {
      newErrors.phone = 'Phone number must be 10 digits';
    }
    if (!formValues.message) newErrors.message = 'Message is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setIsSubmitting(true);

      // Sending email with EmailJS
      emailjs.send(
        'service_qda9r1p',        
        'template_fxaqhvb',      
        {
          name: formValues.name,
          email: formValues.email,
          phone: formValues.phone,
          message: formValues.message
        },
        'NcGgN4drK0cL1rQnO'        
      )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setSuccessMessage('Message sent successfully!');
        setFormValues({ name: '', email: '', phone: '', message: '' });
        setErrors({});
      })
      .catch((error) => {
        console.error('FAILED...', error);
        setErrors({ form: 'Failed to send message, please try again.' });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
    }
  };

  return (
    <div className="mx-auto py-12 px-6 sm:px-12 lg:px-32">
      <h1 style={{ fontFamily: "Cinzel, serif" }} className="text-3xl sm:text-4xl font-bold text-center text-[#eab308]">
        Contact <span className="text-black">Us</span>
      </h1>

      <div className="flex flex-col lg:flex-row mt-12 lg:mx-16 space-y-8 lg:space-y-0 ">
        {/* Contact Details Section */}
        <div className="lg:w-1/3 bg-[#eab308] p-6 md:p-8 text-white rounded-md">
          <div className="mb-6">
            <img src={img1} alt="img" />
            <p className="mt-4 text-sm sm:text-md">
            C 98, Hanuman Mandir Rd, Kucha Pati Ram, Connaught Place, New Delhi, Delhi, 110001s
            </p>
          </div>
          
          <div className="mb-6">
            <img src={img2} alt="img" />
            <p className="mt-4 text-sm sm:text-md">msmepcbi@gmail.com</p>
          </div>
          <div className="mb-6">
            <img src={img3} alt="img" />
            <p className="mt-4 text-sm sm:text-md">+91 8260040640</p>
          </div>
        </div>

        {/* Contact Form Section */}
        <div className="lg:w-2/3 bg-orange-50 p-6 md:p-8 rounded-md">
          <h2 className="text-xl sm:text-2xl font-bold mb-6 md:mb-8 font-serif">
            Send Us a <span className="text-[#eab308]">Message</span>
          </h2>
          {successMessage && <p className="text-green-500 text-sm mb-4">{successMessage}</p>}
          {errors.form && <p className="text-red-500 text-sm mb-4">{errors.form}</p>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block mb-2 text-sm" htmlFor="name">
                Name
              </label>
              <input
                className="w-full p-2 border bg-slate-50 border-gray-300 rounded outline-none"
                type="text"
                id="name"
                placeholder="Name"
                value={formValues.name}
                onChange={handleChange}
              />
              {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
            </div>
            <div className="mb-4">
              <label className="block mb-2 text-sm" htmlFor="email">
                Email
              </label>
              <input
                className="w-full p-2 border bg-slate-50 border-gray-300 rounded outline-none"
                type="email"
                id="email"
                placeholder="Email"
                value={formValues.email}
                onChange={handleChange}
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
            </div>
            <div className="mb-4">
              <label className="block mb-2 text-sm" htmlFor="phone">
                Phone
              </label>
              <input
                className="w-full p-2 border bg-slate-50 border-gray-300 rounded outline-none"
                type="text"
                id="phone"
                placeholder="Phone"
                value={formValues.phone}
                onChange={handleChange}
              />
              {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
            </div>
            <div className="mb-4">
              <label className="block mb-2 text-sm" htmlFor="message">
                Message
              </label>
              <textarea
                className="w-full p-2 border bg-slate-50 border-gray-300 rounded outline-none"
                id="message"
                placeholder="Message"
                value={formValues.message}
                onChange={handleChange}
              ></textarea>
              {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
            </div>

            <button type="submit" className="bg-[#eab308] hover:bg-[#ca9a04] text-white px-6 py-3 font-medium rounded-lg w-full md:w-1/3 xl:w-1/4" disabled={isSubmitting}>
              {isSubmitting ? 'Sending...' : 'Submit'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
