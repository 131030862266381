import React from "react";
import NotFound from "../Screens/Common/NotFound";
import Home from "../Screens/User/Home";
import About from "../Screens/User/About";
import Contact from "../Screens/User/Contact";
import Services from "../Screens/User/Services";
import Blogs from "../Screens/User/Blogs";
import OurMainObjectives from "../Screens/User/OurMainObjectives";
import ChairmanMessage from "../Screens/User/ChairmanMessage";
import CentralCommittees from "../Screens/User/CentralCommittees";
import StateWiseCommittee from "../Screens/User/StateWiseCommittee";
import DistrictWiseCommittee from "../Screens/User/DistrictWiseCommittee";
import CouncilProfile from '../Screens/User/CouncilProfile'
const userRoutes = [
  { path: "/", component: <Home /> },
  { path: "/ourMainObjectives", component: <OurMainObjectives /> },
  { path: "/chairmanMessage", component: <ChairmanMessage /> },
  { path: "/councilProfile", component: <CouncilProfile /> },
  { path: "/centralCommittees", component: <CentralCommittees /> },
  { path: "/stateWiseCommittee", component: <StateWiseCommittee /> },
  { path: "/districtWiseCommittee", component: <DistrictWiseCommittee/> },
  { path: "/contact", component: <Contact /> },
  { path: "/services", component: <Services /> },
  { path: "/blogs", component: <Blogs /> },
  { path: "*", component: <NotFound /> },
];

export { userRoutes };
