import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Swiper.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import ActivitySlide1 from './ActivitySlide1'
import ActivitySlide2 from './ActivitySlide2'
import ActivitySlide3 from './ActivitySlide3';
const ActivitiesSwiper = () => {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
         <ActivitySlide1/>
        </SwiperSlide>
        <SwiperSlide>
        <ActivitySlide2/>
        </SwiperSlide>
        <SwiperSlide>
        <ActivitySlide3/>
        </SwiperSlide>
      </Swiper>
    </>)
}

export default ActivitiesSwiper