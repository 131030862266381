import React from "react";
import MainImage from "../../Assets/About/MainImage.jpg";
import { FaCheck } from "react-icons/fa6";
import SimpleBanner from "../Common/SimpleBanner";
import AboutUs_banner from '../../Assets/Home/AboutUs1.png'

// Object to store the content dynamically
const objectives = [
  {
    id: 1,
    title:
      "1. The objective of MSME PROMOTION COUNCIL INDIA is to provide information to people about cottage, micro and small scale industries under the guidelines of “MSME”.",
    points: [
      "Organizing programs under MSMEPCI Kaushal Vikas Yojana, forming groups of unemployed men and women at district, block, panchayat, and village level, organizing seminars, webinars etc. about cottage, micro and small scale industries under “MSME” along with giving complete information to every citizen in detail, as well as encouraging them to make them aware for employment in their hometown panchayat and village itself.",
      "Facilitating and connecting e-learning platforms for startups and raising awareness.",
      "Unemployment is a worldwide problem which is currently the major problem of not only underdeveloped but also developed nations. The main reason for unemployment is excess population growth, illiteracy and lack of money which at present remains the cause of a great disappointment in all classes. In which it can be said that the excessive growth of population is less than the growth of employment opportunities due to which unemployment is increasing excessively.",
      "Poverty in India is also a major reason for any person to be unemployed. Due to poverty in the country, proper resources are not available to the people and apart from this the poor person is also deprived of a good education. Because of which he is not able to get a good employment and a large number of sections remain unemployed. To encourage such unemployed people to get employment by giving detailed information about “MSME” industries by MSMEPCI as well as doing special work for them.",
    ],
  },
  {
    id: 2,
    title: "2. Promotion of “MAKE IN INDIA” scheme of Government of India.",
    points: [
      "The “MAKE IN INDIA” scheme was launched by the illustrious Prime Minister of India, Shri Narendra Modi ji, as part of a wider set of nation-building initiatives. The main objective of the “MAKE IN INDIA” scheme of the Government of India is to make the country a hub of product production so that by manufacturing all the products in the country, they work for exporting to their country as well as other countries. And work to make India’s economy more strong. Under this mission, the objective of Honorable Prime Minister Modi is also that the products used everyday in people’s lives can be manufactured in India only.",
      "Under this mission, the production of the product should be maximum in India and the price of the product should also be reduced and the country’s economy should get maximum benefits by exporting the product to other countries. When more and more products are made inside the country, then this will increase the employment in the country, people will get employment and the level of unemployment in India will decrease.",
      "When the production of the product will be done inside the country, then the tax on the product coming from outside will be saved, so that the citizens of India will get the good quality product at a low cost and due to the manufacturing of the product in the country, the investors of other countries will come to our country. Will invest money so that money from other countries will come to our country, this will further improve the economy of our country.",
      "Under the “MAKE IN INDIA” scheme, the objective of MSMEPCI is to provide employment opportunities in the country and also to prevent the youth from going from their state to other country/state for employment. The main objective of this scheme is to work to make India a powerful country in the field of product manufacturing in the whole world by promoting product manufacturing activities in India.",
      "The main objective of MSMEPCI under “MAKE IN INDIA” scheme is to make India a global manufacturing hub by encouraging domestic and international manufacturing companies to invest/manufacture in India and generate employment in India.",
    ],
  },

  {
    id: 3,
    title: "3. Providing information about loans under MSME schemes.",
    points: [
      "Providing information about necessary loan procedures and loan types under MSME schemes, MSMEPCI at its level to provide assistance to people in releasing your loan amount from banks after complete processing and verification of all your documents under MSME schemes.",
      "MSME is an important scheme for those small business owners, under which any businessman can get capital through easy loan process under MSMEPCI to further his business. To make efforts for small businesses to get the necessary capital to expand their business operations etc.",
      "Loans taken by people in the past and in the present time are not able to be repaid due to some reason. Due to which the bank puts it in the category of NPA, keeping this problem in mind, MSMEPCI has taken an important decision that Timely cooperation will be provided by the Assosiates of MSMEPCI (Mba Trained, Chartered Acountant, Financial Advisor, Production Advisor, Promotion Advisor etc) through which people will not face any problem in their production, purchase of material or promotion in future. In lieu of providing all these facilities, MSMEPCI will make the borrower sign an agreement with these options, under which borrower should only invest the amount of his loan in the place for which the loan has been granted to him.",
      "If a borrower has taken a loan and due to some reason is unable to pay back part of the loan amount within the stipulated time (for example, if the loan tenure is 10 years and the borrower has repaid the loan amount honestly on time for 8 years except that due to some reason the remaining amount of 2 years could not be repaid by the borrower) then it is also the responsibility of the bank to know the reason. To resolve this, MSMEPCI will mediate between the bank and the borrower and make an agreement between the bank and the borrower, which should be accepted by both the parties.",
    ],
  },

  {
    id: 4,
    title:
      "4. Providing assistance to small traders so that they can expand their business.",
    points: [
      "Providing assistance to small traders to grow their business through various schemes under “MSME” by MSMEPCI.",
      "Expanding the product line for merchants, launching e-commerce websites, franchising and trying to promote business in various ways.",
      "Providing assistance to cottage, micro and small traders to set up industries so that their business can grow.",
    ],
  },

  {
    id: 5,
    title:
      "5. Providing assistance in their project report along with encouraging them to set up new industries and providing loans from banks.",
    points: [
      "Providing assistance to the clients in preparing their project reports by the legal team of MSMEPCI as per the requirements of the client and their industry.",
      "MSMEPCI Related Person try to help you in making project report of your industry as per your industry functions.",
    ],
  },

  {
    id: 6,
    title: "6. Free registration of business under MSME Portal.",
    points: [
      "PAN card",
      "Aadhar card",
      "Proof of business registration",
      "Certificate of Incorporation",
      "Address certificate of place of business",
      "Proof of identity and address of the promoters/directors",
      "Bank Account Statement / Canceled CheckDigital Signature",
      "Authorization letter/board resolution for authorized signatory.",
      "No Objection Certificate (NOC) from the owner of the property",
    ],
  },
  {
    id: 7,
    title:
      "7. In the direction of providing full time and part time employment to rural women and help through self help groups etc.",
    points: [
      "In this program, women empowerment has to be strengthened under the slogan “Empowering Women-Empowering India” by Honorable Prime Minister of the country, Honorable Shri Narendra Modi.",
      "With the need to improve women’s participation in the economy, MSME PROMOTION COUNCIL INDIA will provide tools & employment to such women workers, who are constantly moving between different types of work (like pickle, papad making, embroidery, knitting, cutting, sewing etc.) according to their work capacity in their home town.",
      "The rural economy contributes a significant amount to the national income of India, therefore, to make efforts to address the problems of rural women and act in accordance with the need to take immediate action.",
      "Housewives women who want to work part time/full time work at home as per their efficiency, desire and skill. Providing work and related equipment to such women by MSMEPCI at their home and making proper payment from women for their finished production so that they can earn their own income and they can help their family financially.",
    ],
  },
  {
    id: 8,
    title:
      "8. Buying products from the manufacturer itself and promoting exports.",
    points: [
      "Keeping in mind the “MAKE IN INDIA” scheme, MSMEPCI will promote the product for sale and if necessary, if your product is not being sold, it will promote it at its own level.",
      "MSMEPCI has its own marketing team through which the product will also be marketed.",
      "MSMEPCI will launch its application (buy/sell) in the upcoming phase under which the product will be bought/sold under which if the registered seller’s product is not sold through the application within 7 days, then seller’s buying/selling product Will be taken to national/international market for selling at reasonable prices by MSMEPCI.",
      "In the coming time, a digital application will be launched by MSMEPCI under which registration of cottage, small and medium industries will be done and this registration will automatically generate a digital certificate on the system through which MSMEPCI will provide free assistance and help in tax, gst, purchase/sale and marketing of products etc.",
    ],
  },

  {
    id: 9,
    title:
      "9. To establish small units in rural areas in the country in the coming time so that rural migration can be stopped.",
    points: [
      "Even today, many unemployed people are wandering here and there in our country. Keeping in mind the “Pradhanmantri Self-Employment Scheme” started by the famous Prime Minister of our country, Narendra Modi, the unemployed can get employment through this scheme. Through this, loans are given to the unemployed so that they can start their own employment and earn their living.",
      "MSME PROMOTION COUNCIL INDIA, as per its capacity, to provide employment to unemployed men and women of all categories and also to provide full assistance to the people for setting up micro and micro units.",
      "To provide loans under “Startups India” scheme for setting up cottage and micro industries in rural areas and so that the unemployed can get employment in their hometowns and home villages so that migration can be prevented.",
      "Stopping the migration of unemployed people due to lack of employment in their hometown and rural areas will be the first priority of MSME PROMOTION COUNCIL INDIA.",
    ],
  },

  {
    id: 10,
    title:
      "10. If there are 6 people in a family and there is employment for only 1 person, then if the remaining 5 people agree for employment, then ensuring employment for those 5 people.",
    points: [
      "Grouping women according to their working capacity so that they can work as a group and get maximum employment.",
      "If there are 5 women present in a family and all of them can earn their income by working for a few hours, this will increase the skills of the women as well as the level of respect of the earning member in the family.",
      "They will be provided information about raw materials as well as necessary equipment for the production of goods.",
      "Our aim will be to provide employment to common men and women and increase their work efficiency and literacy by two times to four times. so that the resolution of Prime Minister of our country, Honorable Shri Narendra Modi to doubling the per capita income can be achieved successfully. The aim of MSMEPCI will be to fulfill the resolution.",
    ],
  },

  {
    id: 11,
    title:
      "11. To establish small units in rural areas in the country in the coming time so that rural migration can be stopped.",
    points: [
      "MSME PROMOTION COUNCIL INDIA will also establish its own offices at every district and block level so that more and more people can get employment.",
      "If the employees working in various fields under MSME PROMOTION COUNCIL INDIA face any kind of problem. So that those employees can get their problems resolved without any fear and they do not have to face any kind of problem and efforts should be made to ensure that more and more people get employment.",
    ],
  },
  {
    id: 12,
    title:
      "12. To work towards reducing imports and increasing exports under the dreams of “MAKE IN INDIA” and Local for Vocal of the illustrious Prime Minister of our country, Honorable Shri Narendra Modi Ji and after achieving our main objective, to work towards international Expanding your objectives for the business.",
    points: [
      "Amidst the Corona crisis in the country, the illustrious Prime Minister of our country, Honorable Shri Narendra Modi , had started the “Self-reliant India Campaign”. Along with this, the slogan “Local for Vocal” was given by Prime Minister Modi ji. The meaning of this slogan is that not only the products made in the country should be used but they should also be promoted at their own level so that more and more people become aware of the use of indigenous products. If the local products of the country are promoted under this campaign, then small traders will develop.",
      "MSME PROMOTION COUNCIL INDIA To work towards reducing the import of product by providing employment to unemployed people and getting the products produced.",
      "Exporting the finished product in the name of MSME PROMOTION COUNCIL INDIA and expanding the Make in India scheme as much as possible.",
      "MSME PROMOTION COUNCIL INDIA To make every effort to work towards reducing imports to zero and increasing exports as much as possible by producing products in our country.",
    ],
  },
  {
    id: 13,
    title: "3. To help the farmers in the agriculture sector.",
    points: [
      "India is an agricultural country. Agriculture is the backbone of the Indian economy and about 52 percent of the country’s total labor force earns its livelihood from agriculture and its allied industries and businesses. At present, due to population explosion, the agricultural sector has lagged behind, because the nature of agricultural work is very backward, lack of techniques in agricultural work, unemployment has increased due to lack of institutional reforms like land reform, ram consolidation, landholding limit etc. Apart from tenancy reform, and some political and administrative inefficiency and lack of sense of cooperation in the behavior of farmers is also the main reason, The problem of unemployment has also increased due to non-availability of good opportunities for agriculture, a lot of rural population migrated to the cities..",
      "MSME PROMOTION COUNCIL INDIA To always work to cooperate with all such youth and unemployed people to provide them employment in their village and hometown itself, so that efforts should be made to stop the migration of youth and unemployed from cities and towns.",
      "MSME PROMOTION COUNCIL INDIA is ready to help those farmers to get rid of that problem without any terms and conditions, MSMEPCI is standing and will help to those micro agriculture farmers who are not able to do farming in their land due to financial crisis, money etc.",
    ],
  },

  {
    id: 14,
    title: "14. For the purpose of saving the environment.",
    points: [
      "The main objective of environmental protection is to protect the environment or natural resources for the future. In this century, somewhere in the name of development, we are continuously harming the environment. Due to which we have entered into such a liable state that we cannot survive on this planet for long without environmental protection, under this MSMEPCI is trying to run the following awareness campaign.",
      "Encouraging people to use energy saving products like tube lights and bulbs etc.",
      "Use less paper and wood Encourage the use of e-books and e-paper as much as possible.",
      "Minimizing the use of fossil fuels, encouraging the use of methods such as walking, bicycle, electric-scooter, electric-car or electric-public transport for commuting.",
      "Encouraging the use of jute or cloth bags made by MSME PROMOTION COUNCIL INDIA (MSMEPCI) instead of plastic bags.",
      "Encouraging the use of reusable batteries and solar panels.",
      "Reducing the use of chemical fertilizers and encouraging setting up of compost bins to make manure from cow dung.",
      "MSME PROMOTION COUNCIL INDIA is working in the direction of saving the environment, at its level, the work of planting trees and trees from time to time all over India, so that efforts can be made to save the environment.",
    ],
  },

  {
    id: 15,
    title:
      "15. To make “Swachh Bharat Swasth Bharat” (one step towards cleanliness).",
    points: [
      "To fulfill the dream of our country’s Father of the Nation, Mahatma Gandhi, “Clean India Campaign” was started by the Prime Minister of the country, Honorable Shri Narendra Modi. “Swachh Bharat Abhiyan” is also known as “Nirmal Bharat Abhiyan”.• The main objective of Swachh Bharat is to make India clean. To bring awareness among the people that as they keep their house clean. In the same way, cooperate with MSME PROMOTION COUNCIL INDIA in keeping the country clean. Because our country is our identity. No matter how much we make our house shine. Unless the country shines, our identity will remain the same. Cleanliness is very important for our mental and physical health. Cleanliness of our surrounding areas and environment is very important for social and intellectual health.",
      "Keeping in mind the Swachh Bharat Swasth Abhiyan, MSME PROMOTION COUNCIL INDIA will set up such machines at the colony and village level to dispose of wet and dry waste, garbage, residual debris, etc. coming out of every house in the country. Will work to convert waste into compost manure by recycling / proper waste management.",
    ],
  },

  {
    id: 16,
    title: "16. Gradually expanding its objectives on a “Pan India” basis.",
    points: [
      "MSME PROMOTION COUNCIL INDIA To make efforts to reduce the import of its finished products to zero and to increase the exports as much as possible To make efforts to expand this objective.",
    ],
  },
];

const OurMainObjectives = () => {
  return (
    <div>
      <SimpleBanner image={AboutUs_banner} name="Our Main Objectives" path="Home >> About >> Main Obejctives" />
      <div className="p-4 lg:p-24 pb-4 lg:pb-12 md:px-12 lg:px-16 xl:px-44">
        <div className="mb-4 md:mb-8">
          <img
            src={MainImage}
            alt="img"
            className="w-full mb-4 md:mb-12"
            data-aos="flip-left"
          />
          {objectives.map((objective) => (
            <div
              key={objective.id}
              className="flex flex-col mb-8"
              data-aos="fade-up"
            >
              <h1 className="text-[#141f39] text-2xl font-extrabold mb-4">
                {objective.title}
              </h1>
              {objective.points.map((point, index) => (
                <div
                  key={index}
                  className="flex gap-4 md:gap-2 md:px-8 w-full mb-4"
                >
                  <FaCheck className="w-[10%] h-8 mt-2 text-[#007f61]" />
                  <p className="text-[#4f4f4f] leading-loose text-[16px] w-[90%]">
                    {point}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurMainObjectives;
