
import heroimg from "../../Assets/Home/hero-man8.png";
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Swiper.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import img from '../../Assets/Home/MSME banner.png'
import img2 from '../../Assets/Home/MSME banner 3.png'
import img3 from '../../Assets/Home/MSME banner2.png'
const HeroSection = () => {

 
  return (
    <Swiper
    spaceBetween={30}
    centeredSlides={true}
    autoplay={{
      delay: 2500,
      disableOnInteraction: false,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper"
  >
    <SwiperSlide>
    <img src={img} className="h-96" alt="img"/>
    </SwiperSlide>
    <SwiperSlide>
    <img src={img2} className="h-96" alt="img"/>
    </SwiperSlide>
    <SwiperSlide>
    <img src={img3} className="h-96" alt="img"/>
    </SwiperSlide>
  </Swiper>
   
  );
};

export default HeroSection;


// <div className="bg-[#007f61] text-white">
    //   <div className="grid grid-cols-1 md:grid-cols-2 mx-4 md:mx-16">
    //     <div className="flex flex-col justify-center py-5 md:py-0s">
    //       <p className="text-white font-semibold md:text-xl" data-aos="fade-up">
    //       MSMEPCBI Works for the Development MSME by empowering Micro small and medium enterprises across diverse industries.
    //       </p>
    //       <div data-aos="flip-up" className="md:block hidden">
    //         <h1 className="text-4xl sm:text-5xl md:text-6xl font-semibold mt-4">
    //         MSME 
    //         </h1>
    //         <h1 className="text-4xl sm:text-5xl md:text-6xl font-semibold mt-4">
    //         Promotion
    //         </h1>
    //         <h1 className="text-4xl sm:text-5xl md:text-6xl font-semibold mt-4">
    //         Council Board India
    //         </h1>
    //       </div>
    //       <div data-aos="flip-up" className="md:hidden block">
    //         <h1 className="text-4xl sm:text-3xl md:text-6xl font-semibold mt-4">
    //           Engaging &
    //         </h1>
    //         <h1 className="text-4xl sm:text-3xl md:text-6xl font-semibold mt-4">
    //           Accessible
    //         </h1>
    //         <h1 className="text-4xl sm:text-3xl md:text-6xl font-semibold mt-4">
    //           <span className="text-[#064585]">Offline </span>Online
    //         </h1>
    //         <h1 className="text-4xl sm:text-3xl md:text-6xl font-semibold mt-4">
    //           Courses For All
    //         </h1>
    //       </div>
    //       <div className="mt-8 flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-7">
    //         <button className="tracking-wide bg-[#064585] text-white py-4 px-6 rounded">
    //           Talk to Us
    //         </button>
    //         <div className="flex items-center space-x-4">
    //           {/* <img
    //             src={callicons}
    //             alt="img"
    //             className="w-7 h-7 md:w-8 md:h-8"
    //           /> */}
    //           {/* <div>
    //             <p className="text-gray-800 text-sm md:text-base">Contact Us</p>
    //             <p className="text-gray-800 font-semibold text-sm md:text-lg">
    //               +91 9861934932
    //             </p>
    //           </div> */}
    //         </div>
    //       </div>
    //     </div>
    //     <div>
    //       <img
    //         className="w-full md:w-[90%] mx-auto"
    //         src={heroimg}
    //         alt="person"
    //         data-aos="zoom-in-left"
    //       />
    //     </div>
    //   </div>
    // </div>