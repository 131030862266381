import React from 'react';
import img1 from '../../Assets/Home/6_points.png';

const OurObjectives = () => {
    return (
        <div className="bg-[#f5f2ef] grid grid-cols-1 md:grid-cols-2 p-4 md:p-8 lg:p-24 gap-8 lg:gap-11 pb-4 lg:pb-12 md:px-12 lg:px-16 xl:px-44">
            <div className="flex justify-center md:justify-start" data-aos="flip-left">
                <img src={img1} alt="MSME logo" className="w-full " />
            </div>
            <div className="mt-8 text-center md:text-left" data-aos="flip-right">
                <h2 className="text-3xl md:text-5xl mb-6 text-[#2d2928] font-bold"style={{ fontFamily: "Cinzel, serif" }}>Our <span className='text-[#eab308]'
                >Objectives</span></h2>
                <p className="mt-4 text-gray-700 leading-loose">
                    The objectives of the MSME Promotion Council India include supporting and developing existing MSMEs, establishing new enterprises, assisting Khadi and village industries, empowering women, creating job opportunities, and developing core industries.
                </p>
                <p className="mt-4 text-gray-700 leading-loose">
                    Organizing programs under MSMEPCI Kaushal Vikas Yojana, forming groups of unemployed men and women at district, blosck, panchayat, and village levels, organizing seminars, webinars, etc., about cottage, micro, and small-scale industries under “MSME” along with giving complete information to every citizen in detail, as well as encouraging them to become aware of employment opportunities in their hometown panchayat and village.
                </p>
                <div className="mt-8">
                <button type="submit" className="bg-[#eab308] hover:bg-[#ca9a04] text-white px-6 py-3 font-medium rounded-lg w-full md:w-1/3 xl:w-1/4" >
                    Read More              
            </button>               </div>
            </div>
        </div>
    );
};

export default OurObjectives;
