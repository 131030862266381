import React from 'react'


const Map = () => {
    return (
        <React.Fragment>
            <div>
                <div className="w-full h-80 lg:h-[500px] mt-10 px-6 md:px-16">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3741.4480064719937!2d85.90515017501131!3d20.323102881155492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a190b0f7a505bd3%3A0x25d83711fdc0b443!2sLaxmi%20Enclave!5e0!3m2!1sen!2sin!4v1731153346412!5m2!1sen!2sin"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                    />
                </div>
            </div>
           
        </React.Fragment>
    )
}

export default Map
