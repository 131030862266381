import React from 'react'
import SimpleBanner from '../Common/SimpleBanner'
import AboutUs_banner from '../../Assets/About/banner.jpg'
import GetInTouch from '../../Components/Home/GetInTouch'
import Map from '../../Components/Home/Map'

const Contact = () => {
  return (
    <div>
      <SimpleBanner
        image={AboutUs_banner}
        name="Contact Us"
        path="Home >> Contact Us"
      />

      <GetInTouch />
      <Map/>
    </div>
  )
}

export default Contact
