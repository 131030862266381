import React from 'react'
import img1 from "../../Assets/Home/Chairman.jpg";
import img2 from "../../Assets/Home/Bibhuti.jpg";
import img3 from "../../Assets/Home/Kulwinder.jpg";
function ActivitySlide1() {
  return (
    <div  className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 justify-between">
      <div className="cursor-pointer relative w-full h-80 sm:h-96 overflow-hidden shadow-lg group">
          <img
            src={img1}
            alt="Aspen Heights - A tall building with modern architecture"
            className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
          />
          <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 p-4 transition-all duration-300 transform translate-y-full group-hover:translate-y-0">
            <h3 className="text-white text-lg font-bold">
              Bibhuti Bhusan Lenka
            </h3>
            <p className="text-gray-300 text-sm mt-1 ">
              National Chairman MSME PCI (India)
            </p>
          </div>
        </div>
        <div className="cursor-pointer relative w-full h-80 sm:h-96 overflow-hidden shadow-lg group">
          <img
            src={img2}
            alt=""
            className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
          />
          <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 p-4 transition-all duration-300 transform translate-y-full group-hover:translate-y-0">
            <h3 className="text-white text-lg font-bold">
              Bibhuti Bhusan Sahu
            </h3>
            <p className="text-gray-300 text-sm mt-1 ">Vice Chairman (India)</p>
          </div>
        </div>
        <div className="cursor-pointer relative w-full h-80 sm:h-96 overflow-hidden shadow-lg group">
          <img
            src={img3}
            alt="Aspen Heights - A tall building with modern architecture"
            className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
          />
          <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 p-4 transition-all duration-300 transform translate-y-full group-hover:translate-y-0">
            <h3 className="text-white text-lg font-bold">Kulwinder Singh</h3>
            <p className="text-gray-300 text-sm mt-1 ">Vice Chairman (India)</p>
          </div>
        </div>
    </div>
  )
}

export default ActivitySlide1
