import React from 'react'
import OurObjectives from '../../Components/Home/OurObjectives'
import Activities from '../../Components/Home/Activities'
import FaqSection from '../../Components/Home/FaqSection'
import MeetingWithDefenceMinister from '../../Components/Home/MeetingWithDefenceMinister'
import HeroSection from '../../Components/Home/HeroSection'
import AboutUs from '../../Components/Home/AboutUs'
import GetInTouch from '../../Components/Home/GetInTouch'

const Home = () => {
  return (
    <div className='overflow-x-hidden'>
      <HeroSection/>
      <AboutUs />
      <MeetingWithDefenceMinister />
      <OurObjectives/>
      <Activities/>
      <FaqSection/>
     <GetInTouch />
    </div>
  )
}

export default Home
