import React from 'react';
import { IoMdMailOpen } from "react-icons/io";
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoLocationOutline } from "react-icons/io5";
import Logo from "../../Assets/logo/logo.png";
import { FaYoutube, FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa6";

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <React.Fragment>
            <footer className="grid grid-cols-1 md:grid-cols-3 gap-6 text-white bg-[#534e0d] lg:px-20 md:px-10 px-5 py-14">
                <div>
                    <img src={Logo} alt="img" className="w-28" />
                    <p>Supporting micro, small, and medium enterprises through financial aid, skill development, technology adoption, policy advocacy, and market access</p>
                    <div className="flex space-x-4 mt-5">
                        <FaYoutube />
                        <FaFacebook />
                        <FaLinkedin />
                        <FaInstagram />
                    </div>
                </div>

                <div>
                    <h2 className="text-lg font-semibold mb-2">Quick Links</h2>
                    <hr className="border-[#eab308] border-[1px] mb-4 w-[140px]" />
                    <ul className='space-y-2'>
                        <li><a href="/" className="hover:text-[#eab308]">Home</a></li>
                        <li><a href="/ourMainObjectives" className="hover:text-[#eab308]">Our Main Objective</a></li>
                        <li><a href="/councilProfile" className="hover:text-[#eab308]">Council Profile</a></li>
                        <li><a href="/chairmanMessage" className="hover:text-[#eab308]">Our Chairman Message</a></li>
                        <li><a href="/centralCommittees" className="hover:text-[#eab308]">Our Central Committee</a></li>

                    </ul>
                </div>

                <div>
                    <h2 className="text-lg font-semibold mb-2">Contact Us</h2>
                    <hr className="border-[#eab308] border-[1px] mb-4 w-[140px]" />
                    <ul className="space-y-2">
                        <li className="flex">
                            <IoMdMailOpen className="text-primaryColor mr-2" />
                            <a href="mailto:info@example.com" className="hover:text-primaryColor">msmepcbi@gmail.com</a>
                        </li>
                        <li className="flex">
                            <BiSolidPhoneCall className="text-primaryColor mr-2" />
                            <a href="tel:+91 8260040640" className="hover:text-primaryColor">+91 8260040640</a>
                        </li>
                        <li className="flex">
                            <IoLocationOutline className="text-primaryColor mr-2" />
                            <span>Address: C 98, Hanuman Mandir Rd, Kucha Pati Ram, Connaught Place, New Delhi, Delhi, 110001</span>
                        </li>
                    </ul>
                </div>
            </footer>

            <div className="flex flex-col md:flex-row justify-between items-center w-full py-4 px-6 text-sm bg-[#0F1425] text-white tracking-wider">
                <div className="text-center md:text-left mb-2 md:mb-0">
                    © {currentYear} MSME. All Right Reserved
                </div>
                <div className="text-center md:text-right">Designed by <a href="https://www.webbocket.com/">Web_Bocket</a></div>
            </div>
        </React.Fragment>
    );
};

export default Footer;
