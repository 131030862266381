import React from 'react'
import activity1 from '../../Assets/About/activity1.png'
import activity2 from '../../Assets/About/activity2.png'
import activity3 from '../../Assets/About/activity3.png'
import activity4 from '../../Assets/About/activity4.png'

const data = [
    {
        id: 1,
        logo: activity1,
        title: "MSME Trade Showcase",
        content: "Organizing trade fairs and exhibitions to showcase the products and services of MSMEs",
    },

    {
        id: 2,
        logo: activity2,
        title: "MSME Skills Enhancement",
        content: "Providing training and capacity building support to MSMEs to enhance their skills and knowledge",
    },

    {
        id: 3,
        logo: activity3,
        title: "MSME Finance Access",
        content: "Facilitating access to finance for MSMEs through partnerships with financial institutions",
    },

    {
        id: 4,
        logo: activity4,
        title: "MSME Growth Advocacy",
        content: "Advocating for policies and regulations that promote the growth and development of MSMEs",
    },
]

function KeyActivities() {
  return (
    <div className="p-6 lg:p-24 pb-4 lg:pb-12 md:px-12 lg:px-16 xl:px-44 bg-slate-100 items-center flex flex-col">
        <h2 className='text-[#eab308] font-extrabold text-center mb-4'>Key Activities</h2>
        <h1 className='text-[#141f39] text-3xl md:text-5xl w-full lg:w-[80%] font-extrabold text-center capitalize '>Some of the key activities of the
        MSME Promotion Council Board India include:</h1>

        <div className='mt-8 md:mt-16 grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-8'>
            {data.map((data) =>(
                <div key={data.id} className='bg-white p-8 shadow-xl rounded-lg flex gap-4 md:gap-10'data-aos="flip-up">
                    <img src={data.logo} alt="" className=' h-fit'/>

                    <div className='flex flex-col'>
                        <h1 className='text-xl md:text-3xl text-[#141f39] mb-6 font-bold'>{data.title}</h1>
                        <p className='text-md md:text-lg text-[#4f4f4f]'>{data.content}</p>
                    </div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default KeyActivities;
