import React from 'react'

const data = [
  { id: 1, name: "Rashmi Tiwari", designation: "Chairman", state: "JHARKHAND", profilePic: "https://storage.googleapis.com/a1aa/image/shHugAxvvPrCEdOWLDQDmmww2feefhiISdUehe4JzjOyX1u7E.jpg" },
  { id: 2, name: "Ponamala Koteswara Rao", designation: "Vice Chairman", state: "ANDHRA PRADESH", profilePic: "https://storage.googleapis.com/a1aa/image/3xkUatPoArKcCxtbwOfocUwpbKlDKYnnj7Ks8JsSaqVvqd3JA.jpg" },
  { id: 3, name: "Susanto Kumar Bardhan", designation: "Vice Chairman", state: "JHARKHAND", profilePic: "https://storage.googleapis.com/a1aa/image/5CgMUAMCPF4wJpUu7Ak4TvscmrxkiRfgBw56NfTRHeCpq2dnA.jpg" },
  { id: 4, name: "Monika Arya", designation: "Vice Chairman", state: "JHARKHAND", profilePic: "https://storage.googleapis.com/a1aa/image/etUq4VfV8WvUZESj2xVwe5MLL4wwGfsYDgvQKAQzlYfwqa3dC.jpg" },
  { id: 5, name: "Ahmad Hussain", designation: "Vice Chairman", state: "JHARKHAND", profilePic: "https://storage.googleapis.com/a1aa/image/GPyWm034u36yD1SpkHjh4SOw3dsnUpeY6b7VzHQe0UNXV7uTA.jpg" },
  { id: 6, name: "Ricky", designation: "Vice Chairman", state: "JHARKHAND", profilePic: "https://storage.googleapis.com/a1aa/image/Mf78ttgR5C0CeEZxuGnG8RmTw56LnhiqPGTqfc4Uqyz2q2dnA.jpg" },
  { id: 7, name: "Ravi Prakash Singh", designation: "Vice Chairman", state: "JHARKHAND", profilePic: "https://storage.googleapis.com/a1aa/image/JdheguTfaebPWIEZ1lPe16Kmu6isgT27yz8s78413fYAsa3dC.jpg" },
  { id: 8, name: "Jasbir Singh", designation: "Vice Chairman", state: "RAJASTHAN", profilePic: "https://storage.googleapis.com/a1aa/image/PvVdUKLWLIocEVtjigJFqzWQ2UfSAcFZPOe9fedK2TfDra3dC.jpg" },
  { id: 9, name: "Yash Vardhan Modi", designation: "Vice Chairman", state: "RAJASTHAN", profilePic: "https://storage.googleapis.com/a1aa/image/lCt4f91I8bScfUEt4x5W1Hxiq4UKrnhFwRfIXKHsR8Rzq2dnA.jpg" },
  { id: 10, name: "Subash Singh", designation: "Vice Chairman", state: "RAJASTHAN", profilePic: "https://storage.googleapis.com/a1aa/image/xZvCTWkRrwKjDJtfuMXj9fFNypHTpHNeVyi2LEXiGH86q2dnA.jpg" },
];
function StateWiseCommittee() {
  return (
    <div className='py-10'>
     <div className="max-w-7xl mx-auto bg-white p-6 rounded-lg shadow-lg items-center">
          <div className="flex justify-between mb-4">
            <div className="flex space-x-4">
              <div>
                {/* <select className="border border-gray-300 rounded-md p-2">
                  <option>select State</option>
                </select> */}
              </div>
              <div>
                {/* <select className="border border-gray-300 rounded-md p-2">
                  <option>select Designation</option>
                </select> */}
              </div>
            </div>
            
          </div>

          <div className='mb-8'>
              <input type="text" className="border border-gray-300 rounded-md p-2" placeholder="Search" />
            </div>
          {/* <div className="mb-4">
            <select className="border border-gray-300 rounded-md p-2">
              <option>10</option>
            </select>
            <span className="ml-2">entries per page</span>
          </div> */}
          <table className="min-w-full bg-white">
            <thead>
              <tr className="w-full bg-gray-800 text-white">
                <th className="py-2 px-4">S.N</th>
                <th className="py-2 px-4">Profile Pic</th>
                <th className="py-2 px-4">Name</th>
                <th className="py-2 px-4">Designation</th>
                <th className="py-2 px-4">State</th>
                <th className="py-2 px-4">District</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={item.id} className="border-b">
                  <td className="py-2 px-4 text-center">{index + 1}</td>
                  <td className="py-2 px-4 text-center">
                    <img src={item.profilePic} className="rounded-full" width="50" height="50" />
                  </td>
                  <td className="py-2 px-4">{item.name}</td>
                  <td className="py-2 px-4">{item.designation}</td>
                  <td className="py-2 px-4">{item.state}</td>
                  <td className="py-2 px-4"></td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4 flex justify-between items-center">
            <div>Showing 1 to 10 of 32 entries</div>
            <div>
              <ul className="inline-flex items-center -space-x-px">
                <li>
                  <a href="#" className="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700">«</a>
                </li>
                <li>
                  <a href="#" className="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">1</a>
                </li>
                <li>
                  <a href="#" className="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">2</a>
                </li>
                <li>
                  <a href="#" className="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">3</a>
                </li>
                <li>
                  <a href="#" className="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">4</a>
                </li>
                <li>
                  <a href="#" className="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700">»</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
    </div>
  )
}

export default StateWiseCommittee;
