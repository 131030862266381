import React from "react";
import Meeting from "../../Assets/Home/Chairman.jpg";

function MeetingWithDefenceMinister() {
  return (
    <div className="p-4 lg:p-24 pb-4 lg:pb-12 md:px-12 lg:px-16 xl:px-44">
      <h1 className="text-center text-3xl md:text-5xl font-bold font-serif mb-8 text-[#141f39]  ">
        Chairman's <span className="text-[#eab308]">Message</span>
      </h1>
      <div
        className="flex flex-col lg:flex-row items-center"
        data-aos="fade-up-right"
      >
        
        <div className="" data-aos="fade-up-left">
          {/* <p className="bg-[#eab308] text-white p-8 text-md md:text-lg rounded-l-full">
          Micro enterprises are the backbone of the Indian economy. The country's high poverty rate and low per capita income compared to developed nations highlight the critical role these businesses play in driving local economies and enhancing community resilience.
          </p> */}

          <div class=" mx-auto lg:rounded-l-full border rounded-lg shadow-xl relative text-center">
          <div className="flex flex-col gap-4 lg:gap-0 lg:flex-row items-center">
          <img
          src={Meeting}
          alt="Chairman Photo"
          className="lg:w-[15%] rounded-full border-2  "
        />
            <div class="pl-8 text-gray-700 text-lg relative border-[#eab308] p-4 lg:p-0">
             
              "Micro enterprises are the backbone of the Indian economy. The country's high poverty rate and low per capita income compared to developed nations highlight the critical role these businesses play in driving local economies and enhancing community resilience.
            </div>
          </div>
            <div class="text-right text-gray-500 italic lg:-mt-14 xl:-mt-20 mb-2 sm:p-4">
              - Bibhuti Bhusan Lenka <br/>(Chairman - MSME PCBI)
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeetingWithDefenceMinister;
