import React from 'react'
import { useState } from 'react';

import AjayKumarSoni from '../../Assets/CentralCommittee/AjayKumarSoni.png';
import AloorSantoshKumar from '../../Assets/CentralCommittee/AloorSantoshKumar.png';
import Anil from '../../Assets/Home/Chairman.jpg'
import AnilKumar from '../../Assets/Home/Kulwinder.jpg'
import AnimallSrinivas from '../../Assets/CentralCommittee/AnimallaSrinivas.jpeg';
import ArshadKhan from '../../Assets/CentralCommittee/ArshadKhan.png';
import AshiqHussain from '../../Assets/CentralCommittee/AshiqHussain.png';
import AshokMishra from '../../Assets/CentralCommittee/AshokMishra.png';
import AshutoshRai from '../../Assets/CentralCommittee/AshutoshRai.png';
import Deepak from '../../Assets/CentralCommittee/Deepak.png';
import DeepChandra from '../../Assets/CentralCommittee/DeepChandra.jpeg';
import DurgraNaga from '../../Assets/Home/Rajkumar.jpg';
import Feroz from '../../Assets/CentralCommittee/Feroz.jpeg';
import GauravKaushik from '../../Assets/CentralCommittee/GauravKoushik.jpeg';
import Giddaluru from '../../Assets/CentralCommittee/Giddaluru.jpeg';
import JaydevJaswant from '../../Assets/CentralCommittee/JaydevJaswant.jpg';
import MadanPatel from '../../Assets/CentralCommittee/MadanPatel.png';
import MohdNazir from '../../Assets/CentralCommittee/Mohd. Nazir.png';
import PawanKumar from '../../Assets/Home/Nursing.jpg';
import PramodBharti from '../../Assets/CentralCommittee/PramodBharti.png';
import PritamSaini from '../../Assets/CentralCommittee/PritamSaini.png';
import PrashantDwivedi from '../../Assets/CentralCommittee/PrshantDwidevi.png';
import PuvvadaRaja from '../../Assets/CentralCommittee/PuvvadaRaja.png';
import SatishChand from '../../Assets/CentralCommittee/SatishChand.png';
import ShivveerSing from '../../Assets/CentralCommittee/ShivveerSingh.png';
import ShravanKumar from '../../Assets/CentralCommittee/ShravanKumar.png';
import SyedArif from '../../Assets/CentralCommittee/SyedArif.jpeg';
import TilakRaj from '../../Assets/CentralCommittee/TilakRaj.png';
import VijayNaresh from '../../Assets/CentralCommittee/VijayNaresh.jpeg'
import SimpleBanner from '../Common/SimpleBanner';
import AboutUs_banner from '../../Assets/Home/AboutUs1.png'


const centralWorkingCoreCommittee = [
  {
    id: 1,
    imgSrc: Anil,
    name: 'Bibhuti Bhusan Lenka',
    designation: 'Chairman',
  },

  {
    id: 2,
    imgSrc: DurgraNaga,
    name: 'Raj Kumar',
    designation: 'Vice Chairman',
  },

  {
    id: 3,
    imgSrc: AnilKumar,
    name: 'Kulwinder Singh',
    designation: 'Vice Chairman',
  },

  {
    id: 4,
    imgSrc: PawanKumar,
    name: 'Nursing Charan Dash',
    designation: 'Vice Chairman',

  }

];

const humanResourceCommittee = [
  {
    id: 1,
    imgSrc: Deepak,
    name: 'Deepak Ganguli',
    designation: 'Chairman',
  },

  {
    id: 2,
    imgSrc: PramodBharti,
    name: 'Pramod Bharti',
    designation: 'Deputy chairman',
  },

  {
    id: 3,
    imgSrc: PritamSaini,
    name: 'Pritam Saini',
    designation: 'Deputy Vice chairman',
  },

  {
    id: 4,
    imgSrc: PuvvadaRaja,
    name: 'Puvvada Raja Amareswara Rao',
    designation: 'Deputy Vice chairman',
  }
];

const managementCommittee = [
  {
    id: 1,
    imgSrc: ShivveerSing,
    name: 'Shivveer Singh',
    designation: 'Chairman',
  },

  {
    id: 2,
    imgSrc: TilakRaj,
    name: 'Tilak Raj Jain (Ex AGM Indian Overseas Bank)',
    designation: 'Vice chairman',
  },

  {
    id: 3,
    imgSrc: AshiqHussain,
    name: 'Ashiq Hussain Beigh',
    designation: 'Deputy chairman',
  },

  {
    id: 4,
    imgSrc: MohdNazir,
    name: 'Mohd. Nazir',
    designation: 'Deputy chairman',
  },
  
  {
    id: 5,
    imgSrc: AshutoshRai,
    name: 'Ashutosh Rai',
    designation: 'Deputy Vice chairman',
  },
];

const disciplinaryCommittee = [
  {
    id: 1,
    imgSrc: GauravKaushik,
    name: 'Gaurav Kaushik',
    designation: 'Vice Chairman',
  },

  {
    id: 2,
    imgSrc: MadanPatel,
    name: 'Madan Patel',
    designation: 'Deputy chairman',
  },

  {
    id: 3,
    imgSrc: AjayKumarSoni,
    name: 'Ajay Kumar Soni',
    designation: 'Deputy Chairman',
  },

  {
    id: 4,
    imgSrc: PrashantDwivedi,
    name: 'Dr. Prashant Dwivedi',
    designation: 'Deputy Chairman',
  },

  {
    id: 5,
    imgSrc: ArshadKhan,
    name: 'Arshad Khan',
    designation: 'Deputy VIce Chairman',
  },
];

const financeCommittee = [
  {
    id: 1,
    imgSrc: Giddaluru,
    name: 'Giddaluru Sampath Kumar',
    designation: 'Chairman',
  },

  {
    id: 2,
    imgSrc: Feroz,
    name: 'Feroz Mohammed',
    designation: 'Chairman(Working)',
  },

  {
    id: 3,
    imgSrc: DeepChandra,
    name: 'Deep Chandra',
    designation: 'Deputy Vice chairman',
  },

  {
    id: 4,
    imgSrc: SatishChand,
    name: 'Satish Chand',
    designation: 'Deputy Vice chairman',
  },

  {
    id: 5,
    imgSrc: AloorSantoshKumar,
    name: 'Aloor Santosh Kumar',
    designation: 'Deputy Vice chairman',
  },

  {
    id: 6,
    imgSrc: VijayNaresh,
    name: 'Vijay Naresh Sharma',
    designation: 'Deputy Vice chairman',
  },

  {
    id: 7,
    imgSrc: JaydevJaswant,
    name: 'Jaydev Jaswant Bhai Thakar',
    designation: 'Deputy Vice chairman',
  },

];

const projectCommittee = [
  {
    id: 1,
    imgSrc: SyedArif,
    name: 'Syed Arif Ali',
    designation: 'Vice Chairman',
  },

  {
    id: 2,
    imgSrc: AnimallSrinivas,
    name: 'Animall Srinivas',
    designation: 'Deputy chairman',
  },

  {
    id: 3,
    imgSrc: ShravanKumar,
    name: 'Shravan kumar S Bengeri',
    designation: 'Deputy Vice chairman',
  },

  {
    id: 4,
    imgSrc: AshokMishra,
    name: 'Ashok Mishra',
    designation: '',
  }
];



const CentralWorkingCoreCommittee = () => {
  return(<> 

  <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 justify-between items-center'>
    
    {centralWorkingCoreCommittee.map((member) =>(
    <div key={member.id}
    className='flex flex-col flex-grow overflow-y-auto bg-white shadow-lg rounded-lg'>
     
        <img src={member.imgSrc} alt="" className='w-full h-full md:h-[350px] rounded-md  flex-shrink-0'/>
      <h1 className='text-xl font-bold mt-4 px-4 text-[#141f39]'>{member.name}</h1>
      <h2 className='px-4 my-4'>{member.designation}</h2>
    </div>
  ))}
  </div>
  </>
   
  )
}

function HumanResourceCommittee(){
  return(
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 justify-between items-center'>
      {humanResourceCommittee.map((member) =>(
      <div key={member.id}
      className='flex flex-col flex-grow overflow-y-auto bg-white'>
       
          <img src={member.imgSrc} alt="" className='w-full h-full md:h-[400px] rounded-md  flex-shrink-0'/>
        <h1 className='text-xl font-bold mt-4 px-4 text-[#141f39]'>{member.name}</h1>
        <h2 className='px-4 mt-4'>{member.designation}</h2>
      </div>
    ))}
    </div>
  )
}

function ManagementCommittee(){
  return(
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 justify-between items-center'>
      {managementCommittee.map((member) =>(
      <div key={member.id}
      className='flex flex-col flex-grow overflow-y-auto bg-white shadow-lg  rounded-2xl '>
       
          <img src={member.imgSrc} alt="" className='w-full h-full md:h-[400px] flex-shrink-0'/>
        <h1 className='text-xl font-bold mt-4 px-4 text-[#141f39]'>{member.name}</h1>
        <h2 className='px-4 mt-4'>{member.designation}</h2>
      </div>
    ))}
    </div>
  )
}


function DisciplinaryCommittee(){
  return(
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 justify-between items-center'>
      {disciplinaryCommittee.map((member) =>(
      <div key={member.id}
      className='flex flex-col flex-grow overflow-y-auto bg-white'>
       
          <img src={member.imgSrc} alt="" className='w-full h-full md:h-[400px] rounded-md  flex-shrink-0'/>
        <h1 className='text-xl font-bold mt-4 px-4 text-[#141f39]'>{member.name}</h1>
        <h2 className='px-4 mt-4'>{member.designation}</h2>
      </div>
    ))}
    </div>
  )
}


function FinanceCommittee(){
  return(

    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 justify-between items-center'>
      {financeCommittee.map((member) =>(
      <div key={member.id}
      className='flex flex-col flex-grow overflow-y-auto bg-white'>
       
          <img src={member.imgSrc} alt="" className='w-full h-full md:h-[400px] rounded-md  flex-shrink-0'/>
        <h1 className='text-xl font-bold mt-4 px-4 text-[#141f39]'>{member.name}</h1>
        <h2 className='px-4 mt-4'>{member.designation}</h2>
      </div>
    ))}
    </div>
  )
}

function ProjectCommittee (){
  return(
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 justify-between items-center'>
      {projectCommittee.map((member) =>(
      <div key={member.id}
      className='flex flex-col flex-grow overflow-y-auto bg-white'>
       
          <img src={member.imgSrc} alt="" className='w-full h-full md:h-[400px] rounded-md  flex-shrink-0'/>
        <h1 className='text-xl font-bold mt-4 px-4 text-[#141f39]'>{member.name}</h1>
        <h2 className='px-4 mt-4'>{member.designation}</h2>
      </div>
    ))}
    </div>
  )
}


function CentralCommittees() {

  const [committee, setCommittee] = useState('All');
  return (
   <>
     <SimpleBanner
        image={AboutUs_banner}
        name="Our Committee"
        path="Home >> About >>Our Committee"
      />
    <div className='flex flex-col p-4 lg:p-24 pb-4 lg:pb-12 md:px-12 lg:px-16 xl:px-44 items-center'>
      <h2 className='text-[#eab308] text-center font-extrabold text-md md:text-lg mb-4 ' >Central Committee</h2>
      <h1 className='text-center text-3xl md:text-5xl font-bold md:w-[50%]'>Our MSME PCI Central Committees</h1>
      <div className='flex flex-wrap mt-8 gap-4 items-center text-center'>
        <button className='`bg-transparent px-4 py-3 border border-[#eab308] rounded-md font-bold text-[#4f4f4f] hover:bg-[#eab308]'
        onClick={() => setCommittee('All')}>
          Show All 
        </button>
        <button className='bg-transparent px-4 py-3 border border-[#eab308] rounded-md font-bold text-[#4f4f4f] hover:bg-[#eab308]'
        onClick={() => setCommittee('All')}>
          Central Working Core Committee
        </button>
        <button className='bg-transparent px-4 py-3 border border-[#eab308] rounded-md font-bold text-[#4f4f4f] hover:bg-[#eab308]'
        onClick={() => setCommittee('Human Resource Committee')}>
        Human Resource Committee 
        </button>
        {/* <button className='bg-[#eab308] px-4 py-3 rounded-md font-bold text-[#4f4f4f]'
        onClick={() => setCommittee('All')}>
          Management & Governance Committee
        </button>
        <button className='bg-[#eab308] px-4 py-3 rounded-md font-bold text-[#4f4f4f]'
        onClick={() => setCommittee('All')}>
          Disciplinary Committee 
        </button>
        <button className='bg-[#eab308] px-4 py-3 rounded-md font-bold text-[#4f4f4f]'
        onClick={() => setCommittee('All')}>
          Finance Committee
        </button>
        <button className='bg-[#eab308] px-4 py-3 rounded-md font-bold text-[#4f4f4f] text-center'
        onClick={() => setCommittee('All')}>
          Project Committee 
        </button> */}
      </div>

      <div className='mt-8'>
    
      {committee === "All" && (
       <CentralWorkingCoreCommittee />
      )}
      
      {committee === "Human Resource Committee" && (
       <HumanResourceCommittee />
      )}
      </div>
    </div>
   </>
  )
}

export default CentralCommittees
